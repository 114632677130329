import { StateService } from '@uirouter/angularjs';
import { IUserService } from './../../shared/userService';
import { ITranslationService } from './../i18n/translationService';
import { INotificationService } from './../login/notificationService';
import { IPermissionService } from './../permissions/permissionService';
import { ITreeListScope } from './../treeListController/ITreeListScope';

export class ProgramManagementController {

    private verificationStatus: any = {
        pending: true, // to prevent screen flickering
        failed: false,
        hasPermission: false,
        data: []
    };
    private userPermissions: any = { granted: [] };

    private readonly programManagementEvent: string = "progmanEvent";

    static $inject = [
        "$scope",
        "$state",
        "$window",
        "notificationService",
        "permissionService",
        "translationService",
        "userService"
    ];

    constructor(
        public $scope: ITreeListScope,
        private $state: StateService,
        private $window: Window,
        private notificationService: INotificationService,
        private permissionService: IPermissionService,
        private translationService: ITranslationService,
        private userService: IUserService
    ) {
        this.translationService.getTextLabels(this.$scope);

        this.permissionService.getAllPermissions(this.userPermissions, this.verificationStatus, this.$scope)
            .then(() => {
                // programManagementEvent is used for notifications on changed access rights, for which the page needs to be refreshed
                // The latter is the reason for subscribing to these websocket events
                $scope.$on("$destroy",
                    () => {
                        this.notificationService.removeStringFromClientInfo(this.programManagementEvent);
                    });

                this.notificationService
                    .subscribeToWebSocketEvent($scope,
                        this.programManagementEvent,
                        (event, message) => {
                            this.userService.refreshPage();
                        });

                this.notificationService.addStringToClientInfo(this.programManagementEvent);
            });
    }

    userHasPermission(permissionName) {
        return this.userPermissions.granted.indexOf(permissionName) > -1;
    }

    openOrganizationUnitsPage() {
        this.$window.location.href = "/angularomrp/program-management/organization-units";
    }

    openOrganizationUnitSettingsPage() {
        this.$window.location.href = "/angularomrp/program-management/organization-units-configurations";
    }

    openResourceTypesPage() {
        this.$window.location.href = "/angularomrp/program-management/resource-types";
    }

    openResourcePropertiesPage() {
        this.$window.location.href = "/angularomrp/program-management/resource-properties";
    }

    openSkillsPage() {
        this.$window.location.href = "/angularomrp/program-management/skills";
    }

    openSkillLevelsPage() {
        this.$window.location.href = "/angularomrp/program-management/skill-levels";
    }

    openUsersPage() {
        this.$window.location.href = "/angularomrp/program-management/users";
    }

    openUserGroupsPage() {
        this.$window.location.href = "/angularomrp/program-management/user-groups";
    }

    openActivityTypesPage() {
        this.$state.transitionTo("activityTypes", {});
    }

    openExchangeConfigurationsPage() {
        this.$state.transitionTo("exchangeUpdateConfig", {});
    }

    openNarrowCastingConfigurationPage() {
        this.$state.transitionTo("narrowCastingConfiguration", {});
    }

    openHolidaysPage() {
        this.$window.location.href = "/angularomrp/program-management/holidays";
    }

    openOwsInterface() {
        this.$window.location.href = "angularomrp/ows-interface";
    }
}