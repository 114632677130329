import { StateService } from '@uirouter/angularjs';
import { IPageStartService } from './../../shared/pageStartService';
import { IUserService } from './../../shared/userService';
import { IConfigurationService } from '../../shared/configurationService';

import { ITranslationService } from './../i18n/translationService';

import { Planboard } from './../planboard/entities/planboard';
import { ActivityType } from './../planboard/entities/activitytype';
import * as Globals from './../planboard/utils/globals';
import { TimeSpan } from './../planboard/utils/timespan';

import { ITreeListScope } from './../treeListController/ITreeListScope';

import { Dictionary } from './../utils/dictionary';
import * as Timezone from './../utils/timezone';

export class ViolationsController {

    queryStartDate = new Date();
    queryEndDate = new Date();
    resourceTypes = new Dictionary();
    resourceTypeId = 0;
    violationsLoaded = false;
    filteredViolations: Array<any> = [];
    violationResourceFilter = "";
    hideUnplanSelectedViolationsButton = false;
    selectAll: boolean;
    disabledSelectAllButton: boolean;

    private commonSvc: any;
    private pendingUnplanActions = 0;
    private planboardScenarioId = Planboard.scenarioId;
    private planboardActivityTypes = Planboard.activityTypes;
    private planboardResourceTypeId = Planboard.activities.activeResourceSelectionId;
    private resourceDisplayNames = new Dictionary();
    private allSelectedActivityIds = new Dictionary();
    private loadedViolations: Array<any> = [];
    private violationsDidLoad = false;
    private resourceNamesDidLoad = false;
    private resourceFilterTimer: angular.IPromise<any> = null;
    private violations: Array<any> = [];

    private readonly dialogToken = "violations";

    static $inject = [
        "$scope",
        "$filter",
        "$state",
        "$timeout",
        "pageStartService",
        "translationService",
        "userService",
        "configurationService"
    ];
    constructor(
        public $scope: ITreeListScope,
        private $filter: ng.IFilterService,
        private $state: StateService,
        private $timeout: ng.ITimeoutService,
        private pageStartService: IPageStartService,
        private translationService: ITranslationService,
        private userService: IUserService,
        private configurationService: IConfigurationService
    ) {
        this.translationService.getTextLabels(this.$scope);
        this.commonSvc = this.pageStartService.initialize(this.$scope, null, this.dialogToken);

        this.$scope.$on("$destroy", () => {
            Planboard.timedRefresh(); // TODO: test that this refreshes planboard
        });

        this.commonSvc.start(() => { this.initializeViolationsPage(); });
    }

    private initializeViolationsPage(): void {
        if (this.planboardResourceTypeId) this.resourceTypeId = this.planboardResourceTypeId; // set to planboard value
        if (this.userService.getUserVariable("VIOLATIONS_RESOURCE_FILTER")) {
            this.violationResourceFilter = this.userService.getUserVariable("VIOLATIONS_RESOURCE_FILTER");
            this.userService.deleteUserVariable("VIOLATIONS_RESOURCE_FILTER");
        }
        this.initializeDates();
        this.loadResourceTypes();
    }

    onResourceTypeChanged(itemId: number): void {
        if (itemId === this.resourceTypeId) return;
        this.selectAll = false;
        this.resourceTypeId = itemId;
        this.checkDatesAndLoadResources();
    }

    onResourceFilterChanged(): void {
        if (this.resourceFilterTimer) this.$timeout.cancel(this.resourceFilterTimer);
        this.resourceFilterTimer = this.$timeout(() => {
            this.createFilteredViolationsList();
        }, 1000);
    }

    onCheckbox(violation: any, activity: any): void {
        if (activity.selected) {
            this.allSelectedActivityIds.add(activity.id, activity);
        } else {
            this.allSelectedActivityIds.remove(activity.id);
        }

        this.updateMatchingActivitySelections(activity.id, activity.selected);
    }

    onSelectAll(): void {
        for (const violation of this.filteredViolations) {
            for (const activity of violation.activities) {
                if (!activity.isCheckboxDisabled) {
                    activity.selected = this.selectAll;
                    if (this.selectAll) {
                        this.allSelectedActivityIds.add(activity.id, activity);
                    } else {
                        this.allSelectedActivityIds.remove(activity.id);
                    }
                    this.updateMatchingActivitySelections(activity.id, activity.selected);
                }
            }
        }
    }

    onStartDateChanged(date: Date): void {
        var newStartDate = new Date(date.getTime());
        newStartDate.setHours(0, 0, 0, 0);
        this.queryStartDate = newStartDate;
        this.checkDatesAndLoadResources();
    }

    onEndDateChanged(date: Date): void {
        var newEndDate = new Date(date.getTime());
        newEndDate.setHours(0, 0, 0, 0);
        this.queryEndDate = newEndDate;
        this.checkDatesAndLoadResources();
    }

    onActivityClick(activityId: number): void {
        if (activityId > 0) this.$state.transitionTo("planboard.activity", { activityId: activityId });
    }

    onUnplanViolation(violation: any): void {
        if (violation.selectedActivityIds.count === 0)
            return;
        var daymarkList = [];
        violation.selectedActivityIds.forEach((id, violationActivity) => {
            if (violationActivity.activityType.categoryId !== ActivityType.daymarkCategoryId) { // not daymark
                this.pendingUnplanActions++;
            } else { // daymark
                daymarkList.push(violationActivity.activity);
            }
        });
        if (daymarkList.length > 0) {
            this.pendingUnplanActions++;
            this.removeActivities(daymarkList);
        }
        violation.selectedActivityIds.forEach((id, violationActivity) => {
            if (violationActivity.activityType.categoryId !== ActivityType.daymarkCategoryId) { // not daymark
                violationActivity.activity.resourceId = null;
                this.unplanActivity(violationActivity.activity);
            }
        });
    }

    onUnplanMultipleViolations(violations: Array<any>): void {
        var daymarkList = [];
        var activitiesList = [];

        var promise = new Promise((resolve, reject) => {
            violations.forEach((violation) => {
                if (violation.selectedActivityIds.count === 0) {
                    return;
                }

                violation.selectedActivityIds.forEach((id, violationActivity) => {
                    if (violationActivity.activityType.categoryId !== ActivityType.daymarkCategoryId) { // not daymark
                        activitiesList.push(violationActivity.activity);
                    } else { // daymark
                        daymarkList.push(violationActivity.activity);
                    }
                });
            });

            if (daymarkList.length > 0) {
                this.pendingUnplanActions++;
                this.removeActivities(daymarkList);
            }

            if (activitiesList.length > 0) {
                this.pendingUnplanActions++;
                this.unplanActivities(activitiesList)
            }
        });

        promise.then(() => {
            if (this.selectAll) this.selectAll = false;
        });
    }

    isStartDateValid(): boolean {
        if (!this.queryStartDate) return false; // No start date
        return true;
    }

    isEndDateValid(): boolean {
        if (!this.queryEndDate) return false; // No end date
        if (!this.queryStartDate) return true; // No start date
        if (TimeSpan.getDayNr(this.queryEndDate) - TimeSpan.getDayNr(this.queryStartDate) < 0) return false; // end date earlier than start
        return true;
    }

    private initializeDates(): void {
        if (this.userService.getUserVariable("VIOLATIONS_START_DATE") && this.userService.getUserVariable("VIOLATIONS_END_DATE")) {
            this.queryStartDate = this.userService.getUserVariable("VIOLATIONS_START_DATE");
            this.queryEndDate = this.userService.getUserVariable("VIOLATIONS_END_DATE");
            return;
        }
        this.queryStartDate = TimeSpan.today.toDate();
        this.queryEndDate = TimeSpan.fromDateNoTime(this.queryStartDate).addDays(27).toDate();
    }

    private loadResourceTypes(): void {
        // Note: api request could be skipped if we store the resourceType display names in planboard entity
        this.commonSvc.loadData("api/ResourceTypes/ForPlanningBoard", this.resourceTypes,
            (success, loadInto) => {
                this.checkDatesAndLoadResources();
            }, null, true, true);
    }

    private loadResources(): void {
        var resourceFilterParams =
        {
            resourceTypeId: this.resourceTypeId,
            referenceDate: Timezone.rollDateForWebApi(this.queryStartDate),
            beforeReferenceDate: Timezone.rollDateForWebApi(this.queryEndDate),
            sortByResourcePropertyIds: null,
            sortDescending: false,
            applyUnitFilter: false,
            applyResourceFilter: false
        };

        this.commonSvc.post("api/Resources/ForPlanningBoard/Filtered", resourceFilterParams,
            (success) => {
                var resources = success.data;
                var resourceIds = this.getResourceIds(resources);
                this.loadViolations(resourceIds);
            },
            null, true);
    }

    private getResourceIds(resources: Array<any>): Array<number> {
        var resourceIds: Array<number> = [];
        for (var i = 0; i < resources.length; i++)
            resourceIds.push(resources[i].id);
        return resourceIds;
    }

    private loadViolations(resourceIds: Array<number>): void {
        this.loadedViolations = [];
        var violationParams = {
            periodStart: Timezone.rollDateForWebApi(this.queryStartDate),
            periodEnd: Timezone.rollDateForWebApi(this.queryEndDate),
            scenarioId: this.planboardScenarioId,
            resourceIds: resourceIds
        };

        this.commonSvc.post("api/Violations", violationParams,
            (success) => {
                this.loadedViolations = success.data;
                resourceIds = [];
                for (var i = 0; i < this.loadedViolations.length; i++)
                    resourceIds.push(this.loadedViolations[i].resourceId);
                this.violationsDidLoad = true;
                this.loadResourceDisplayNames(resourceIds);
            },
            null, true);
    }

    private loadResourceDisplayNames(resourceIds: Array<number>): void {
        var resources = { idList: resourceIds };
        this.commonSvc.post("api/Resources/WithId", resources,
            (success) => {
                for (var i = 0; i < success.data.length; i++)
                    this.resourceDisplayNames.add(success.data[i].id, success.data[i].displayName);

                this.resourceNamesDidLoad = true;
                this.setViolations();
            }, null, true);
    }

    private setViolations(): void {
        if (this.violationsDidLoad && this.resourceNamesDidLoad) {
            this.violationsLoaded = false;
            this.violations = [];
            this.filteredViolations = [];

            for (var i = 0; i < this.loadedViolations.length; i++) {
                var violation = this.loadedViolations[i];
                var structuredViolation = {
                    resourceName: this.getResourceDisplayName(violation.resourceId),
                    activities: this.setActivityData(violation.activities),
                    start: violation.start,
                    end: violation.end,
                    dateRange: this.getDateRange(violation.start, violation.end),
                    timeRange: this.getTimeRange(violation.start, violation.end),
                    isButtonDisabled: false,
                    description: this.getViolationDescription(violation),
                    selectedActivityIds: new Dictionary(),
                    violationsWithoutPermission: violation.violationsWithoutPermission

                };

                if (this.selectAll) {
                    for (const activity of structuredViolation.activities) {
                        activity.selected = this.selectAll;
                    }
                }

                structuredViolation.isButtonDisabled = this.shouldDisableButton(structuredViolation.activities);
                structuredViolation.selectedActivityIds = this.getSelectedActivityIds(structuredViolation.activities);
                this.violations.push(structuredViolation);
            }
            this.createFilteredViolationsList();
            this.violationsLoaded = true;
            this.violationsDidLoad = this.resourceNamesDidLoad = false;
        }
    }

    private getResourceDisplayName(resourceId: number): string {
        if (resourceId)
            return this.resourceDisplayNames.value(resourceId);

        return "-";
    }

    private shouldDisableButton(activities: Array<any>): boolean {
        for (var i = 0; i < activities.length; i++)
            if (!activities[i].isCheckboxDisabled)
                return false;

        return true;
    }

    private setActivityData(activities: Array<any>): any {
        var structuredActivities = [];
        for (var i = 0; i < activities.length; i++) {
            var isReadOnly = activities[i].isReadOnly;
            var activity = activities[i].activity;
            var activityType = this.planboardActivityTypes.getObject(activity.activityTypeId);
            var structuredActivity = {
                id: activity.id,
                activity: activity,
                activityType: activityType,
                backColor: this.getActivityBackColor(activityType),
                textColor: this.getActivityTextColor(activityType),
                shortText: this.getActivityShortText(activityType),
                dateRange: this.getDateRange(activity.startDate, activity.endDate),
                timeRange: this.getTimeRange(activity.startDate, activity.endDate),
                selected: this.allSelectedActivityIds.tryGetValue(activity.id, (value) => { }),
                isCheckboxDisabled: isReadOnly
            };
            structuredActivities.push(structuredActivity);
        }

        return structuredActivities;
    }

    private getViolationDescription(violation: any): string {
        return this.$scope.textLabels["VIOLATION_TYPE_" + violation.type.toString()];
    }

    private getSelectedActivityIds(activities: Array<any>): Dictionary {
        var selectedActivityIds = new Dictionary();
        for (var i = 0; i < activities.length; i++) {
            var activity = activities[i];
            if (activity.selected)
                selectedActivityIds.add(activity.id, activity);
        }
        return selectedActivityIds;
    }

    private getActivityBackColor(activityType: any): string {
        if (activityType)
            return activityType.backColor;

        return "#eeeeee";
    }

    private getActivityTextColor(activityType: any): string {
        if (activityType)
            return activityType.textColor;

        return "#222222";
    }

    private getActivityShortText(activityType: any): string {
        if (activityType)
            return activityType.shortText;

        return "-";
    }

    private getDateRange(start: Date, end: Date): string {
        var startDate = Timezone.correctTimeZoneInfo(start);
        var endDate = Timezone.correctTimeZoneInfo(end);
        var startDateStr = Globals.dateFormat(startDate, 'shortDate');
        var endDateStr = Globals.dateFormat(endDate, 'shortDate');

        if (startDateStr === endDateStr)
            return startDateStr;
        else
            return startDateStr + " - " + endDateStr;
    }

    private getTimeRange(start: Date, end: Date): string {
        var startDate = Timezone.correctTimeZoneInfo(start);
        var endDate = Timezone.correctTimeZoneInfo(end);
        var startTimeStr = Globals.dateFormat(startDate, 'shortTime');
        var endTimeStr = Globals.dateFormat(endDate, 'shortTime');

        return startTimeStr + " - " + endTimeStr;
    }

    private checkDatesAndLoadResources(): void {
        this.violationsLoaded = false;
        if (this.isStartDateValid() && this.isEndDateValid() && this.resourceTypeId > -1) {
            this.saveUserDates();
            this.loadResources();
        }
    }

    private saveUserDates(): void {
        this.userService.setUserVariable("VIOLATIONS_START_DATE", this.queryStartDate);
        this.userService.setUserVariable("VIOLATIONS_END_DATE", this.queryEndDate);
    }

    private unplanActivity(activity: any): void {
        this.commonSvc.post("api/Activities/ChangeActivity", activity,
            (success) => {
                this.pendingUnplanActions--;
                if (this.pendingUnplanActions === 0) {
                    this.checkDatesAndLoadResources();
                }
            },
            (error) => {
                this.pendingUnplanActions--;
                if (this.pendingUnplanActions === 0) {
                    this.checkDatesAndLoadResources();
                }
                this.commonSvc.httpErrorResponse(error, () => { });
            }, true);
    }

    private removeActivities(activityList: Array<any>): void {
        Planboard.deleteMultipleActivities(
            activityList.map((a) => { return a.id; }),
            this.commonSvc,
            this.$filter("date"),
            () => { // success
                this.pendingUnplanActions--;
                if (this.pendingUnplanActions === 0) {
                    this.checkDatesAndLoadResources();
                }
            },
            () => { // failure
                this.pendingUnplanActions--;
                if (this.pendingUnplanActions === 0) {
                    this.checkDatesAndLoadResources();
                }
            }
        );
    }

    private unplanActivities(activityList: Array<any>): void {
        Planboard.unplanMultipleActivities(
            activityList.map((a) => { return a.id; }),
            this.commonSvc,
            this.$filter("date"),
            () => { // success
                this.pendingUnplanActions--;
                if (this.pendingUnplanActions === 0) {
                    this.checkDatesAndLoadResources();
                }
            },
            () => { // failure
                this.pendingUnplanActions--;
                if (this.pendingUnplanActions === 0) {
                    this.checkDatesAndLoadResources();
                }
            }
        );
    }
    private updateMatchingActivitySelections(activityId: number, selected: boolean) {
        for (var i = 0; i < this.filteredViolations.length; i++) {
            var violation = this.filteredViolations[i];
            for (var j = 0; j < violation.activities.length; j++) {
                var activity = violation.activities[j];
                if (activity.id === activityId) {
                    activity.selected = selected;
                    if (selected)
                        violation.selectedActivityIds.add(activity.id, activity);
                    else
                        violation.selectedActivityIds.remove(activity.id);
                    break;
                }
            }
        }
    }

    private setHideUnplanSelectedViolationsButtonState(): void {
        this.hideUnplanSelectedViolationsButton = this.filteredViolations.every(violation =>
            violation.activities.every(activity => activity.isCheckboxDisabled)
        );
    }

    private setDisabledSelectAllButtonState(): void {
        this.disabledSelectAllButton = this.filteredViolations.every(violation =>
            violation.activities.every(activity => activity.isCheckboxDisabled)
        );
    }

    private createFilteredViolationsList(): void {
        this.filteredViolations = [];
        if (this.violationResourceFilter === "") {// no filter
            this.filteredViolations = this.violations;
            this.setHideUnplanSelectedViolationsButtonState();
            this.setDisabledSelectAllButtonState();

            return;
        }
        var filter = this.violationResourceFilter.trim()?.toLowerCase(); // filter to lower
        for (var i = 0; i < this.violations.length; i++) {
            var violation = this.violations[i];
            if (violation.resourceName?.trim().toLowerCase().indexOf(filter) >= 0) // resourceName contains filter
                this.filteredViolations.push(violation);
        }

        this.setHideUnplanSelectedViolationsButtonState();
        this.setDisabledSelectAllButtonState();
    }

}